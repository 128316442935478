import {
  /**
   * FIXME - This should really be a label that happens to be sized like an h4.
   * Refactor to `Box` and test
   */
  // eslint-disable-next-line no-restricted-imports
  Typography,
} from "@mui/material";
import { type ReactElement } from "react";

interface QuestionLabelProps {
  children: string;
}

export function QuestionLabel(props: QuestionLabelProps): ReactElement {
  const { children } = props;
  return (
    <Typography variant="h4" component="label">
      {children}
    </Typography>
  );
}
