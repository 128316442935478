import { ModalStatus, useModalState } from "@clipboard-health/ui-react";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useToast } from "@src/appV2/lib/Notifications/Toasts/useToast";
import { useState } from "react";
import { generatePath, useHistory } from "react-router-dom";

import { useGetQuestions } from "../api/useGetQuestions";
import { usePostRating } from "../api/usePostRating";
import { WorkplaceReviewsRouterPath } from "../paths";
import { RatingDialog } from "./Dialogs/RatingDialog";
import { ReviewQuestionnaireDialog } from "./Dialogs/ReviewQuestionnaire";

interface ReviewFormProps {
  showIsRatingSuccessDialog: () => void;
  workplaceDetails: {
    id: string;
    name: string;
    type: string;
  };
}

export function ReviewForm(props: ReviewFormProps) {
  const { showIsRatingSuccessDialog, workplaceDetails } = props;
  const { showErrorToast } = useToast();
  const history = useHistory();

  const ratingModalState = useModalState(ModalStatus.OPEN);
  const reviewQuestionnaireModalState = useModalState();

  const [rating, setRating] = useState(0);

  const { data: questionsData, isSuccess: questionsLoadedSuccessfully } = useGetQuestions({
    workplaceType: workplaceDetails.type,
    workplaceId: workplaceDetails.id,
  });

  const { mutate: mutatePostRating } = usePostRating(workplaceDetails.id, {
    onSuccess: ({ data }) => {
      const submittedRating = data.attributes.rating;
      setRating(submittedRating);

      logEvent(APP_V2_USER_EVENTS.WORKPLACE_REVIEWS_RATING_SUBMITTED, {
        workplaceId: workplaceDetails.id,
        rating: submittedRating,
      });

      if (questionsData?.data?.length) {
        reviewQuestionnaireModalState.openModal();
      } else {
        showIsRatingSuccessDialog();
      }

      ratingModalState.closeModal();
    },
    onError: () => {
      showErrorToast("Something went wrong while posting rating");
    },
  });

  return (
    <>
      <RatingDialog
        open={questionsLoadedSuccessfully && ratingModalState.modalIsOpen}
        workPlaceDetails={workplaceDetails}
        onSubmit={(rating: number) => {
          mutatePostRating({ rating });
        }}
        onClose={() => {
          ratingModalState.closeModal();
        }}
      />
      <ReviewQuestionnaireDialog
        modalState={reviewQuestionnaireModalState}
        questions={questionsData?.data ?? []}
        rating={rating}
        workPlaceDetails={workplaceDetails}
        onSuccess={() => {
          history.replace(
            generatePath(WorkplaceReviewsRouterPath.REVIEWS_SUMMARY_PATH, {
              workplaceId: workplaceDetails.id,
            })
          );
        }}
      />
    </>
  );
}
