import { useModalState } from "@clipboard-health/ui-react";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { Button, Skeleton } from "@mui/material";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

import { useGetQuestions } from "../api/useGetQuestions";
import { useGetWorkplaceWorkerShifts } from "../api/useGetWorkplaceWorkerShifts";
import { type WorkplaceDetails } from "../types";
import { ReviewQuestionnaireDialog } from "./Dialogs/ReviewQuestionnaire";
import { WorkedHereBeforeButton } from "./WorkedHereBeforeReviews";

interface LeaveReviewFormAndButtonProps {
  workplaceDetails: WorkplaceDetails;
  onSuccess: () => void;
}

export function LeaveReviewFormAndButton(props: LeaveReviewFormAndButtonProps) {
  const { workplaceDetails, onSuccess } = props;
  const reviewQuestionnaireModalState = useModalState();

  const { data: questions = { data: [] }, isLoading: isLoadingQuestions } = useGetQuestions({
    workplaceType: workplaceDetails.type,
    workplaceId: workplaceDetails.userId,
  });

  const worker = useDefinedWorker();

  const { data: shifts = [], isLoading: isLoadingShifts } = useGetWorkplaceWorkerShifts({
    workerId: worker.userId,
    workplaceId: workplaceDetails.userId,
  });

  if (isLoadingQuestions || isLoadingShifts) {
    return (
      <Skeleton
        animation="wave"
        aria-label="Loading Leave a Review Button"
        width="60%"
        height={60}
      />
    );
  }

  if (questions.data.length === 0) {
    return null;
  }

  if (shifts.length === 0) {
    return (
      <WorkedHereBeforeButton
        workplaceDetails={workplaceDetails}
        questions={questions.data ?? []}
        onSuccess={onSuccess}
      />
    );
  }

  return (
    <>
      <Button
        startIcon={<RateReviewIcon />}
        variant="outlined"
        sx={{ width: "fit-content" }}
        onClick={() => {
          reviewQuestionnaireModalState.openModal();
        }}
      >
        Leave a Review
      </Button>
      <ReviewQuestionnaireDialog
        modalState={reviewQuestionnaireModalState}
        questions={questions.data ?? []}
        rating={0}
        workPlaceDetails={{ ...workplaceDetails, id: workplaceDetails.userId }}
        onSuccess={onSuccess}
      />
    </>
  );
}
