import { Stack, TextField } from "@mui/material";
import { useController } from "react-hook-form";

import { QuestionLabel } from "./Label";

interface CommentQuestionProps {
  name: string;
  question: string;
}

export function CommentQuestion(props: CommentQuestionProps) {
  const { name, question } = props;
  const {
    field,
    formState: { isSubmitting },
  } = useController({
    name,
  });
  return (
    <Stack spacing={1}>
      <QuestionLabel>{question}</QuestionLabel>
      <TextField
        {...field}
        fullWidth
        multiline
        placeholder="Leave a response"
        rows={4}
        disabled={isSubmitting}
        InputProps={{
          sx: { borderRadius: 1 },
          inputProps: { maxLength: 1000 },
        }}
      />
    </Stack>
  );
}
