import { type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, DialogActions, DialogContent } from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs";
import { FullScreenDialogTitle } from "@src/appV2/lib/Dialogs/FullScreen/DialogTitle";
import { type InfiniteData, useQueryClient } from "@tanstack/react-query";

import {
  getPaginatedWorkplaceCommentsQueryKey,
  mergePaginatedWorkplaceComments,
  type PaginatedWorkplaceCommentsParams,
  usePaginatedWorkplaceComments,
} from "../../api/usePaginatedWorkplaceComments";
import {
  type WorkplaceCommentsResponse,
  type WorkplaceCommentType,
} from "../../api/useWorkplaceComments";
import { useResetAndRefetchPaginatedComments } from "../../hooks/useResetAndRefetchPaginatedComments";
import { CommentCard } from "../Comments/CommentCard";
import { ReviewCommentForm } from "../Comments/ReviewCommentForm";
import { RepliesList } from "./RepliesList";

interface CommentRepliesDialogProps {
  modalState: UseModalState;
  // FIXME: have standard type for workPlaceDetails and use that instead of this subset props
  workPlaceDetails: {
    userId: string;
    name: string;
  };
  comment: WorkplaceCommentType;
  onUpdate: (comment: WorkplaceCommentType) => void;
}

export function CommentRepliesDialog(props: CommentRepliesDialogProps) {
  const { modalState, comment, workPlaceDetails, onUpdate } = props;
  const { showErrorToast } = useToast();
  const queryClient = useQueryClient();

  const workplaceCommentsParams: PaginatedWorkplaceCommentsParams = {
    workplaceId: workPlaceDetails.userId,
    filter: { parentCommentId: comment.id },
  };

  const {
    data: repliesData,
    isLoading,
    isRefetching,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch: refetchWorkplaceComments,
  } = usePaginatedWorkplaceComments(workplaceCommentsParams, {
    onError: () => {
      showErrorToast("Something went wrong while loading replies");
    },
  });

  const { resetAndRefetchPages } = useResetAndRefetchPaginatedComments({
    refetchComments: refetchWorkplaceComments,
    workplaceCommentsParams,
  });

  return (
    <FullScreenDialog modalState={modalState}>
      <FullScreenDialogTitle onClose={modalState.closeModal}>
        {workPlaceDetails.name}
      </FullScreenDialogTitle>
      <DialogContent dividers sx={{ padding: 0, alignItems: "center" }}>
        <Box
          sx={{
            paddingRight: 1,
            paddingTop: 1,
          }}
        >
          <CommentCard
            comment={comment}
            workplaceId={workPlaceDetails.userId}
            onUpdate={onUpdate}
          />
        </Box>
        <RepliesList
          workplaceId={workPlaceDetails.userId}
          replyPages={repliesData?.pages ?? []}
          isLoading={isLoading || isRefetching}
          isFetchingNextPage={isFetchingNextPage}
          onUpdate={(updatedReply) => {
            queryClient.setQueryData<InfiniteData<WorkplaceCommentsResponse>>(
              getPaginatedWorkplaceCommentsQueryKey(workplaceCommentsParams),
              (previousData) => mergePaginatedWorkplaceComments(updatedReply, previousData)
            );
          }}
          onLoadNextPage={hasNextPage ? fetchNextPage : undefined}
        />
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          paddingX: 1,
          paddingTop: 1,
          paddingBottom: "env(safe-area-inset-bottom)",
        }}
      >
        <ReviewCommentForm
          workplaceId={workPlaceDetails.userId}
          parentId={comment.id}
          inputPlaceholder="Add Reply..."
          anonymousCheckboxLabel="I want my reply to be anonymous"
          onSubmit={async ({ comment }) => {
            if (isDefined(comment)) {
              onUpdate(comment);
            }

            await resetAndRefetchPages();
          }}
        />
      </DialogActions>
    </FullScreenDialog>
  );
}
