import { Stack } from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { type InfiniteData, useQueryClient } from "@tanstack/react-query";

import {
  getPaginatedWorkplaceCommentsQueryKey,
  mergePaginatedWorkplaceComments,
  type PaginatedWorkplaceCommentsParams,
  usePaginatedWorkplaceComments,
} from "../api/usePaginatedWorkplaceComments";
import { type WorkplaceCommentsResponse } from "../api/useWorkplaceComments";
import { useResetAndRefetchPaginatedComments } from "../hooks/useResetAndRefetchPaginatedComments";
import { AggregateType, type WorkplaceDetails } from "../types";
import { Aggregates } from "./Aggregates";
import { AskQuestionFormAndButton } from "./AskQuestionFormAndButton";
import { CommentsList } from "./Comments";
import { LeaveReviewFormAndButton } from "./LeaveReviewFormAndButton";
import { RatingsSummary } from "./RatingsSummary";

interface ReviewsSummaryContentProps {
  workPlaceDetails: WorkplaceDetails;
}

export function ReviewsSummaryContent(props: ReviewsSummaryContentProps) {
  const { workPlaceDetails } = props;
  const { showErrorToast } = useToast();
  const queryClient = useQueryClient();

  const workplaceCommentsParams: PaginatedWorkplaceCommentsParams = {
    workplaceId: workPlaceDetails.userId,
  };

  const {
    data: workplaceCommentsData,
    isLoading,
    hasNextPage,
    isRefetching,
    fetchNextPage,
    isFetchingNextPage,
    refetch: refetchWorkplaceComments,
  } = usePaginatedWorkplaceComments(workplaceCommentsParams, {
    onError: () => {
      showErrorToast("Something went wrong while loading comments");
    },
  });

  const { resetAndRefetchPages } = useResetAndRefetchPaginatedComments({
    refetchComments: refetchWorkplaceComments,
    workplaceCommentsParams,
  });

  return (
    <Stack spacing={1} alignItems="center">
      <RatingsSummary workplaceId={workPlaceDetails.userId} />
      <AskQuestionFormAndButton
        workplaceId={workPlaceDetails.userId}
        onSubmit={async () => {
          await resetAndRefetchPages();
        }}
      />
      <LeaveReviewFormAndButton
        workplaceDetails={workPlaceDetails}
        onSuccess={async () => {
          await resetAndRefetchPages();
        }}
      />
      <Aggregates workPlaceDetails={workPlaceDetails} type={AggregateType.MULTIPLE_CHOICE} />
      <Aggregates workPlaceDetails={workPlaceDetails} type={AggregateType.THREAD} />
      <CommentsList
        workPlaceDetails={workPlaceDetails}
        commentPages={workplaceCommentsData?.pages ?? []}
        isLoading={isLoading || isRefetching}
        isFetchingNextPage={isFetchingNextPage}
        onUpdate={(updatedComment) => {
          queryClient.setQueryData<InfiniteData<WorkplaceCommentsResponse>>(
            getPaginatedWorkplaceCommentsQueryKey(workplaceCommentsParams),
            (previousData) => mergePaginatedWorkplaceComments(updatedComment, previousData)
          );
        }}
        onLoadNextPage={hasNextPage ? fetchNextPage : undefined}
      />
    </Stack>
  );
}
