import { Chip, Stack } from "@mui/material";
import { useController } from "react-hook-form";

import { type ReviewOption } from "../../api/useGetQuestions";
import { QuestionLabel } from "./Label";

interface MultipleChoiceQuestionProps {
  name: string;
  question: string;
  options: ReviewOption[];
}

export function MultipleChoiceQuestion(props: MultipleChoiceQuestionProps) {
  const { name, question, options } = props;
  const {
    field,
    formState: { isSubmitting },
  } = useController({
    name,
  });

  return (
    <Stack spacing={1}>
      <QuestionLabel>{question}</QuestionLabel>
      <Stack useFlexGap direction="row" spacing={1} flexWrap="wrap">
        {options.map((option) => {
          return (
            <Chip
              key={option.id}
              label={option.displayText}
              variant="outlined"
              sx={{
                ...(field.value === option.id && {
                  border: "1px solid #D2E3FC",
                  color: "#1967D2",
                  backgroundColor: "#E8F0FE",
                  "&.MuiChip-clickable:hover": {
                    backgroundColor: "#E8F0FE",
                  },
                }),
              }}
              disabled={isSubmitting}
              onClick={() => {
                field.onChange(option.id);
              }}
            />
          );
        })}
      </Stack>
    </Stack>
  );
}
