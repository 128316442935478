import { type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, DialogActions, DialogContent } from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs";
import { type InfiniteData, useQueryClient } from "@tanstack/react-query";
import pluralize from "pluralize";

import { FullScreenDialogTitle } from "../../../lib/Dialogs/FullScreen/DialogTitle";
import { type AggregateAttributes } from "../../api/useGetMultipleChoiceAggregates";
import { type ThreadAggregate } from "../../api/useGetThreadAggregates";
import {
  getPaginatedWorkplaceCommentsQueryKey,
  mergePaginatedWorkplaceComments,
  type PaginatedWorkplaceCommentsParams,
  usePaginatedWorkplaceComments,
} from "../../api/usePaginatedWorkplaceComments";
import { type WorkplaceCommentsResponse } from "../../api/useWorkplaceComments";
import { useResetAndRefetchPaginatedComments } from "../../hooks/useResetAndRefetchPaginatedComments";
import { AggregateType } from "../../types";
import { AggregateItem } from "../Aggregates/AggregateItem";
import { ReviewCommentForm } from "../Comments/ReviewCommentForm";
import { RepliesList } from "./RepliesList";

interface AggregateRepliesDialogProps {
  type: AggregateType;
  modalState: UseModalState;
  // FIXME: have standard type for workPlaceDetails and use that instead of this subset props
  workPlaceDetails: {
    userId: string;
    name: string;
  };
  aggregate: AggregateAttributes;
  onUpdate: (data: { updatedThreadAggregate: ThreadAggregate }) => void;
}

export function AggregateRepliesDialog(props: AggregateRepliesDialogProps) {
  const { type, modalState, aggregate, workPlaceDetails, onUpdate } = props;
  const { showErrorToast } = useToast();
  const queryClient = useQueryClient();

  const workplaceCommentsParams: PaginatedWorkplaceCommentsParams = {
    workplaceId: workPlaceDetails.userId,
    filter: { questionId: aggregate.questionId },
  };

  const {
    data: repliesData,
    isLoading,
    isRefetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch: refetchWorkplaceComments,
  } = usePaginatedWorkplaceComments(workplaceCommentsParams, {
    onError: () => {
      showErrorToast("Something went wrong while loading replies");
    },
  });

  const { resetAndRefetchPages } = useResetAndRefetchPaginatedComments({
    refetchComments: refetchWorkplaceComments,
    workplaceCommentsParams,
  });

  return (
    <FullScreenDialog modalState={modalState}>
      <FullScreenDialogTitle onClose={modalState.closeModal}>
        {workPlaceDetails.name}
      </FullScreenDialogTitle>
      <Box sx={{ backgroundColor: (theme) => theme.palette.background.paper }}>
        <AggregateItem
          headerText={aggregate.text}
          actionText={pluralize(
            type === AggregateType.THREAD ? "reply" : "response",
            aggregate.repliesCount,
            true
          )}
          questionTags={aggregate.question.tags}
        />
      </Box>
      <DialogContent dividers sx={{ padding: 0, alignItems: "center" }}>
        <RepliesList
          workplaceId={workPlaceDetails.userId}
          replyPages={repliesData?.pages ?? []}
          isLoading={isLoading || isRefetching}
          isFetchingNextPage={isFetchingNextPage}
          onUpdate={(updatedReply) => {
            queryClient.setQueryData<InfiniteData<WorkplaceCommentsResponse>>(
              getPaginatedWorkplaceCommentsQueryKey(workplaceCommentsParams),
              (previousData) => mergePaginatedWorkplaceComments(updatedReply, previousData)
            );
          }}
          onLoadNextPage={hasNextPage ? fetchNextPage : undefined}
        />
      </DialogContent>
      {type === AggregateType.THREAD && (
        <DialogActions
          sx={{
            justifyContent: "center",
            paddingX: 1,
            paddingTop: 1,
            paddingBottom: "env(safe-area-inset-bottom)",
          }}
        >
          <ReviewCommentForm
            workplaceId={workPlaceDetails.userId}
            questionId={aggregate.questionId}
            inputPlaceholder="Add Reply..."
            anonymousCheckboxLabel="I want my reply to be anonymous"
            onSubmit={async ({ threadAggregate }) => {
              if (isDefined(threadAggregate)) {
                onUpdate({ updatedThreadAggregate: threadAggregate });
              }

              await resetAndRefetchPages();
            }}
          />
        </DialogActions>
      )}
    </FullScreenDialog>
  );
}
