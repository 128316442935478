import { Stack } from "@mui/material";
import { type ReactElement } from "react";

import { type ReviewQuestion } from "../../api/useGetQuestions";
import { ReviewQuestionType } from "../../types";
import { CommentQuestion } from "./CommentQuestion";
import { MultipleChoiceQuestion } from "./MultipleChoiceQuestion";

interface Props {
  questions: ReviewQuestion[];
}

export function Questions(props: Props): ReactElement {
  const { questions } = props;

  return (
    <Stack spacing={2} sx={{ textAlign: "start" }}>
      {questions?.map((question) => {
        if (question.attributes.type === ReviewQuestionType.MULTIPLE_CHOICE) {
          return (
            <MultipleChoiceQuestion
              key={question.id}
              name={question.id}
              question={question.attributes.question}
              options={question.attributes.options}
            />
          );
        }

        if (
          question.attributes.type === ReviewQuestionType.THREAD ||
          question.attributes.type === ReviewQuestionType.COMMENT
        ) {
          return (
            <CommentQuestion
              key={question.id}
              name={question.id}
              question={question.attributes.question}
            />
          );
        }

        return null;
      })}
    </Stack>
  );
}
