import { Checkbox, FormControlLabel } from "@mui/material";
import { type ReactElement } from "react";
import { useController } from "react-hook-form";

interface Props {
  name: string;
  label: string;
}

export function AnonymousReviewCheckbox(props: Props): ReactElement {
  const { name, label } = props;
  const {
    field,
    formState: { isSubmitting },
  } = useController({
    name,
  });

  return (
    <FormControlLabel
      labelPlacement="start"
      sx={{ margin: 0 }}
      control={
        <Checkbox
          {...field}
          size="small"
          disabled={isSubmitting}
          sx={{
            color: "#0000008A",
            "&.Mui-checked": {
              color: "#0000008A",
            },
          }}
        />
      }
      label={label}
    />
  );
}
