import { isDefined } from "@clipboard-health/util-ts";
import { Button, Divider, Skeleton, Stack } from "@mui/material";
import { Fragment } from "react";

import {
  type WorkplaceCommentsResponse,
  type WorkplaceCommentType,
} from "../../api/useWorkplaceComments";
import { CommentCard } from "../Comments/CommentCard";
import { WorkerAvatar } from "../WorkerAvatar";

interface RepliesListProps {
  isLoading: boolean;
  replyPages: WorkplaceCommentsResponse[];
  workplaceId: string;
  onUpdate: (comment: WorkplaceCommentType) => void;
  onLoadNextPage?: () => void;
  isFetchingNextPage: boolean;
}

export function RepliesList(props: RepliesListProps) {
  const { isLoading, replyPages, workplaceId, onUpdate, onLoadNextPage, isFetchingNextPage } =
    props;

  if (isLoading) {
    return (
      <Stack width="100%" spacing={1}>
        {[5, 4, 3, 2, 1].map((key) => (
          <Skeleton key={key} variant="rectangular" width="100%" height={100} />
        ))}
      </Stack>
    );
  }

  return (
    <>
      {replyPages.map((replyPage) => (
        <Fragment key={replyPage.links.next ?? "last-replies-page"}>
          {replyPage.data.map((comment, index) => (
            <Fragment key={comment.id}>
              {index !== 0 && <Divider />}
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  marginTop: 2,
                  marginX: 1,
                }}
              >
                <WorkerAvatar workerInfo={comment.attributes.workerInfo} />
                <CommentCard workplaceId={workplaceId} comment={comment} onUpdate={onUpdate} />
              </Stack>
            </Fragment>
          ))}
        </Fragment>
      ))}
      {isDefined(onLoadNextPage) && (
        <Button fullWidth size="small" disabled={isFetchingNextPage} onClick={onLoadNextPage}>
          Load More Replies
        </Button>
      )}
    </>
  );
}
