import { Text } from "@clipboard-health/ui-react";
import { Box, Card, CardContent, LinearProgress, Rating, Skeleton, Stack } from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { RATING_AGGREGATE_EMPTY } from "@src/appV2/Reviews/constants";
import pluralize from "pluralize";

import { useGetRatingAggregates } from "../api/useGetRatingAggregates";
import { getRatingPercentage } from "../utils";

interface RatingsSummaryProps {
  workplaceId: string;
}

export function RatingsSummary(props: RatingsSummaryProps) {
  const { workplaceId } = props;
  const { showErrorToast } = useToast();

  const { data: ratingAggregates = RATING_AGGREGATE_EMPTY, isLoading } = useGetRatingAggregates(
    workplaceId,
    {
      onError: () => {
        showErrorToast("Something went wrong while loading ratings");
      },
    }
  );

  const ratingValues = [5, 4, 3, 2, 1];

  if (isLoading) {
    return <Skeleton variant="rectangular" width="100%" height={100} />;
  }

  return (
    <Card sx={{ borderRadius: 0, padding: 2, width: "100%" }}>
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: 0,
          "&:last-child": {
            padding: 0,
          },
        }}
      >
        <Stack spacing={1} sx={{ width: "60%" }}>
          {ratingValues.map((rating) => {
            return (
              <Box key={String(rating)} display="flex" alignItems="center">
                <Text variant="body2">{rating}</Text>
                <Box width="100%" marginLeft={1}>
                  <LinearProgress
                    variant="determinate"
                    value={getRatingPercentage(rating, ratingAggregates)}
                    sx={{
                      height: 8,
                    }}
                  />
                </Box>
              </Box>
            );
          })}
        </Stack>
        <Stack alignItems="center">
          <Box fontWeight="bold" fontSize={50}>
            {ratingAggregates.avgRating?.toFixed(1)}
          </Box>
          <Rating readOnly value={ratingAggregates.avgRating} precision={0.25} />
          <Text variant="body2">{pluralize("rating", ratingAggregates.numberOfRatings, true)}</Text>
        </Stack>
      </CardContent>
    </Card>
  );
}
